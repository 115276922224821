export const tutorialData = {
  pokemon: {
    id: '1479791170343952100',
    pokemon_id: 16,
    form: 0,
    gender: 1,
    expire_timestamp_verified: 1,
    display_pokemon_id: null,
    ditto_form: null,
    iv: 62.22,
    cp: 111,
    level: 7,
    weight: 1.75875461101532,
    size: 0.29718396067619,
    move_1: 219,
    move_2: 45,
    weather: 0,
    atk_iv: 0,
    def_iv: 14,
    sta_iv: 14,
    cleanPvp: {
      little: [
        {
          pokemon: 16,
          cap: 50,
          value: 394462,
          level: 31,
          cp: 499,
          percentage: 0.98673,
          rank: 13,
          capped: true,
        },
      ],
      great: [
        {
          pokemon: 18,
          cap: 50,
          value: 1948426,
          level: 27,
          cp: 1498,
          percentage: 1,
          rank: 1,
          capped: true,
        },
        {
          pokemon: 18,
          cap: 50,
          value: 1529981,
          level: 15,
          cp: 1489,
          percentage: 0.9912,
          rank: 14,
          evolution: 1,
          capped: true,
        },
      ],
      ultra: [
        {
          pokemon: 18,
          cap: 50,
          value: 3281721,
          level: 25,
          cp: 2482,
          percentage: 0.98763,
          rank: 34,
          evolution: 1,
          capped: true,
        },
      ],
    },
    bestPvp: 1,
  },
  filters: {
    pokestops: {
      allPokestops: false,
      lures: true,
      quests: true,
      invasions: true,
      filter: {
        q1: {
          enabled: false,
          size: 'md',
        },
        'm384-10': {
          enabled: false,
          size: 'md',
        },
      },
    },
    pokemon: {
      hundoIv: true,
      nundoIv: false,
      xlKarp: true,
      xsRat: true,
      ivOr: {
        great: [1, 100],
        ultra: [1, 100],
        iv: [0, 100],
        level: [1, 35],
      },
      standard: {
        enabled: false,
        size: 'md',
        iv: [0, 100],
        atk_iv: [0, 15],
        def_iv: [0, 15],
        sta_iv: [0, 15],
        level: [1, 35],
        great: [1, 100],
        ultra: [1, 100],
      },
      filter: {
        '1-163': {
          enabled: false,
          size: 'md',
          adv: '',
          iv: [0, 100],
          atk_iv: [0, 15],
          def_iv: [0, 15],
          sta_iv: [0, 15],
          level: [1, 35],
          great: [1, 100],
          ultra: [1, 100],
        },
        '1-897': {
          enabled: false,
          size: 'md',
          adv: '',
          iv: [0, 100],
          atk_iv: [0, 15],
          def_iv: [0, 15],
          sta_iv: [0, 15],
          level: [1, 35],
          great: [1, 100],
          ultra: [1, 100],
        },
      },
    },
    gyms: {
      filter: {
        't3-0': {
          enabled: true,
          size: 'md',
        },
        '105-80': {
          enabled: false,
          size: 'md',
        },
      },
    },
    nests: {
      filter: {
        '1-163': {
          enabled: true,
          size: 'md',
        },
        '4-172': {
          enabled: false,
          size: 'md',
        },
      },
    },
  },
  sliders: [
    {
      name: 'iv',
      label: '%',
      min: 0,
      max: 100,
      color: 'secondary',
      disabled: false,
    },
    {
      name: 'great',
      label: 'rank',
      min: 1,
      max: 100,
      color: 'primary',
      disabled: false,
    },
    {
      name: 'ultra',
      label: 'rank',
      min: 1,
      max: 100,
      color: 'primary',
      disabled: false,
    },
    {
      name: 'atk_iv',
      label: '',
      min: 0,
      max: 15,
      perm: 'iv',
      disabled: false,
      color: 'secondary',
    },
    {
      name: 'def_iv',
      label: '',
      min: 0,
      max: 15,
      perm: 'iv',
      disabled: false,
      color: 'secondary',
    },
    {
      name: 'sta_iv',
      label: '',
      min: 0,
      max: 15,
      perm: 'iv',
      disabled: false,
      color: 'secondary',
    },
    {
      name: 'level',
      label: '',
      min: 1,
      max: 35,
      disabled: false,
      color: 'secondary',
    },
  ],
  tiles: {
    gyms: ['t3-0', '105-80'],
    nests: ['1-163', '4-172'],
    pokestops: ['q1', 'm384-10'],
    pokemon: ['1-163', '1-897'],
  },
}
